import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTheme } from './ThemeContext';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAuth } from './AuthContext';
import 'dayjs/locale/en'; // Ensure you're using the correct locale

dayjs.locale('en'); // Set locale to English


const MyTrackApp = () => {
    const { rootUrl } = require('../../config/config');
    const [messages, setMessages] = useState([]);
    const [cars, setCars] = useState([]);
    const { isAuthenticated } = useAuth();
    const [totalAmount, setTotalAmount] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('phone');
    const { theme } = useTheme();
    const[successMessage, setSuccessMessage] = useState('');
    const [showAddMessageForm, setShowAddMessageForm] = useState(false);
    const [newMessage, setNewMessage] = useState({
        phone: '',
        notes: '',
        code: '',
        car:'',
        time: '',
        amount: ''
    });
    const [errors, setErrors] = useState({
        phone: '',
        notes: '',
        code: '',
        car:'',
        time: '',
        amount: ''
    });
    const [editingMessage, setEditingMessage] = useState(null);
    const [viewingMessage, setViewingMessage] = useState(null);
    const [messageText, setMessageText] = useState('');

    useEffect(() => {
        const fetchMessages = async () => {
            const token = localStorage.getItem('authToken')|| 'default-token';
            try {
                const [messageResponse, carResponse] = await Promise.all([
                    axios.get(`${rootUrl}/messages`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }),
                    axios.get(`${rootUrl}/cars`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    })

                ]);
                console.log('cars data', carResponse)
                console.log('messages data', messageResponse)
                setCars(carResponse.data);
                const data = messageResponse.data;

                const mappedData = data.map(item => ({
                    id: item.id,
                    phone: item.phone || 'No sender name',
                    notes: item.notes || 'No message text',
                    car:item.car || 'no car',
                    code: item.code || 'unknown',
                    timestamp: item.time ? new Date(item.time).getTime() : 0,
                    amount: item.amount != null ? item.amount : 0,
                }));

                setMessages(mappedData);

                console.log('Mapped Data:', mappedData);

                const total = mappedData.reduce((acc, message) => acc + message.amount, 0);
                setTotalAmount(total);

              

            } catch (error) {
                console.error("Error fetching messages: ", error);
            }
            
        };

        fetchMessages();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    };
    const validateCode = (code) => {
        const codeRegex = /^[A-Z0-9]{10}$/;
        return codeRegex.test(code);
    };
    const handlePasteMessage = (event) => {
        const message = event.target.value;

        const codeMatch = message.match(/^[A-Z0-9]{10}/);
        const amountMatch = message.match(/Ksh([\d,]+(\.\d{1,2})?)/);
        const phoneMatch = message.match(/(from|to).*?(\d{10})/);
        const dateTimeMatch = message.match(/on\s*(\d{1,2}\/\d{1,2}\/\d{2})\s*at\s*(\d{1,2}:\d{2})\s*(AM|PM)/);

        console.log('Matches:', { codeMatch, amountMatch, phoneMatch, dateTimeMatch });
        
        

        let formattedDate = '';
        if (dateTimeMatch) {
            const [_, date, time, period] = dateTimeMatch;
            const [day, month, year] = date.split('/').map(Number);
            const [hours, minutes] = time.split(':').map(Number);
        
            // Convert to 24-hour format if PM
            const adjustedHours = period === 'PM' && hours < 12 ? hours + 12 : hours;
            const formattedDateString = `${year + 2000}-${month}-${day}T${adjustedHours}:${minutes}`;
        
            const parsedDate = dayjs(formattedDateString);
            console.log('Manually Parsed Date:', parsedDate.format());
        
            
            
           

            console.log('parsedDate:', parsedDate);


        if (parsedDate.isValid()) {
            formattedDate = parsedDate.format('YYYY-MM-DDTHH:mm'); // Ensure proper output format
            console.log('Setting time to:', formattedDate);

            console.log('Formatted Date for input:', formattedDate);
        } else {
            console.error('Invalid Date:', formattedDateString);
        }
    } else {
        console.error('Date/Time match not found in message:', message);
    }

        setNewMessage(prevState => ({
            ...prevState,
            code: codeMatch ? codeMatch[0] : prevState.code,
            amount: amountMatch ? parseFloat(amountMatch[1].replace(/,/g, '')) : prevState.amount,
            phone: phoneMatch ? phoneMatch[2] : prevState.phone,
            time: formattedDate || prevState.time,
            notes: message
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        setMessages([...messages, newMessage]);
        setShowAddMessageForm(false);
        setNewMessage({
            phone: '',
            notes: '',
            car:'',
            code: '',
            time: '',  
            amount: ''
        });
    };

    

    const validateForm = () => {
        const newErrors = {};
        if (!newMessage.phone) newErrors.phone = 'Phone is required';
        if (!newMessage.notes) newErrors.notes = 'Message text is required';
         if (!newMessage.code) newErrors.code ='Code is required'
        if (!newMessage.time) newErrors.time = 'Date/time is required';
       
        if (!newMessage.amount) newErrors.amount = 'Amount is required';
        else if (isNaN(newMessage.amount)) newErrors.amount = 'Amount must be a number';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleAddMessage = async () => {
        let newErrors = {};

        if (!/^\d{10}$/.test(newMessage.phone)) newErrors.phone = 'Phone must be exactly 10 digits';
        if (!newMessage.notes) newErrors.notes = 'Message text is required';
        if (!newMessage.time) newErrors.time = 'Date/time is required';
        if (!newMessage.amount) newErrors.amount = 'Amount is required';
        else if (isNaN(newMessage.amount)) newErrors.amount = 'Amount must be a number';
        if (!validateCode(newMessage.code)) newErrors.code = 'Code must be 10 uppercase letters';
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        try {
            const timestamp = new Date(newMessage.time).toISOString();
    
            const response = await axios.post(`${rootUrl}/messages`, { ...newMessage, time: timestamp });
    
            const addedMessage = {
                id: response.data.id,
                phone: newMessage.phone,
                car: newMessage.car,
                notes: newMessage.notes,
                code: newMessage.code,
                timestamp: new Date(timestamp).getTime(),
                amount: parseFloat(newMessage.amount)
            };
    
            setMessages([...messages, addedMessage]);
            setTotalAmount(totalAmount + parseFloat(newMessage.amount));
            setShowAddMessageForm(false);
            setNewMessage({
                phone: '',
                notes: '',
                car:'',
                code: '',
                time: '',
                amount: ''
            });
            setSuccessMessage('New Mpesa Message added successfully!');
            setTimeout(() => setSuccessMessage(''), 6000);
    
            console.log('Message added:', addedMessage);
        } catch (error) {
            console.error("Error adding message: ", error);
        }
    };
    
    
    
    const handleEditMessage = (id) => {
        const messageToEdit = messages.find(message => message.id === id);
     
        
        const formattedDate = new Date(messageToEdit.timestamp).toISOString().slice(0, 16);// datetime local fomart
        setEditingMessage({ ...messageToEdit, time: formattedDate });
    };
    
    const handleUpdateMessage = async () => {
        if (!editingMessage) return;
    
        let newErrors = {};
    
        if (!/^\d{10}$/.test(editingMessage.phone)) newErrors.phone = 'Phone must be exactly 10 digits';
        if (!editingMessage.notes) newErrors.notes = 'Message text is required';
        if (!editingMessage.time) newErrors.time = 'Date/time is required';
        if (!editingMessage.amount) newErrors.amount = 'Amount is required';
        else if (isNaN(editingMessage.amount)) newErrors.amount = 'Amount must be a number';
        if (!validateCode(editingMessage.code)) newErrors.code = 'Code must be 10 uppercase letters';
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        const { id, phone, car, notes,code, time, amount } = editingMessage;
        const timestamp = new Date(time).toISOString();
    
        if (isNaN(Date.parse(timestamp))) {
            setErrors((prevErrors) => ({ ...prevErrors, time: 'Invalid date/time value' }));
            return;
        }
        try {
            await axios.put(`${rootUrl}/messages/${id}`, { phone, notes, car, code: editingMessage.code, time: timestamp, amount });
            const updatedMessages = messages.map(message =>
                message.id === id ? { ...message, phone, notes, car, code: editingMessage.code, timestamp, amount } : message
            );
            setMessages(updatedMessages);
            setEditingMessage(null);
        } catch (error) {
            console.error("Error updating message: ", error);
        }
    };
    
    
    const handleDeleteMessage = async (id) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this message?');
        if (!isConfirmed) {
            
            return;
        }
        try {
            await axios.delete(`${rootUrl}/messages/${id}`);
            const updatedMessages = messages.filter(message => message.id !== id);
            setMessages(updatedMessages);
            const total = updatedMessages.reduce((acc, message) => acc + message.amount, 0);
            setTotalAmount(total);
        } catch (error) {
            console.error("Error deleting message: ", error);
        }
    };

    const handleViewMessage = (id) => {
        const messageToView = messages.find(message => message.id === id);
        setViewingMessage(messageToView);
    };
    

    const filteredMessages = messages.filter(message => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        switch (searchBy) {
            case 'phone':
                return message.phone.toLowerCase().includes(lowerCaseSearchTerm);
            case 'time':
                return new Date(message.timestamp).toLocaleString().toLowerCase().includes(lowerCaseSearchTerm);
            case 'amount':
                return message.amount.toString().includes(lowerCaseSearchTerm);
            case 'car':
                return message.car.toLowerCase().includes(lowerCaseSearchTerm);
            default:
                return message.code.toLowerCase().includes(lowerCaseSearchTerm);
        }
    });

    return (
        <div className={`min-h-screen ${theme}`}>
            <div className="container mx-auto p-4">
                <h2 className="text-center text-2xl font-bold mb-4">Mpesa Messages</h2>
                {successMessage && (
                <div className="bg-green-300 text-center text-green-800 p-2 rounded mb-4">
                    {successMessage}
                </div>
            )}

                <div className="flex justify-between items-center mb-4">
                    <div>
                        <label className="mr-2">Search By:</label>
                        <select className="border rounded px-2 py-1" value={searchBy} onChange={handleSearchByChange}>
                            <option value="phone">Phone</option>
                            <option value="code">Code</option>
                            <option value="time">Date</option>
                            <option value="amount">Amount</option>
                        </select>
                    </div>
                    <input
                        type="text"
                        placeholder={`Search by ${searchBy}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="border rounded px-2 py-1 w-1/3"
                    />
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-1 rounded"
                        onClick={() => setShowAddMessageForm(true)}
                    >
                        New Message
                    </button>
                </div>
                   

                <table className="border-collapse w-full">
                    <thead>
                        <tr className="bg-slate-800 text-white">
                            <th className="border px-2 py-2 text-left">Code</th>
                            <th className="border px-2 py-2 text-left">Date&time</th>
                            <th className="border px-2 py-2 text-left">Car</th>
                            <th className="border px-2 py-2 text-left">Amount</th>
                            <th className="border px-2 py-2 text-left">Phone</th>
                            <th className="border px-2 py-2 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMessages.map((message, index) => (
                            <tr key={message.id || index} className={index % 2 === 0 ? "bg-white" : "bg-slate-200"}>

                                <td className="border px-2 py-2">{message.code}</td>
                                <td className="border px-2 py-2">{new Date(message.timestamp).toLocaleString()}</td>
                                <td className="border px-2 py-2">{message.car}</td>
                                <td className="border px-2 py-2">{message.amount}</td>
                                <td className="border px-2 py-2">{message.phone}</td>
                                <td className="border px-2  py-2">
                                    <button
                                        className="bg-slate-400  text-white px-2 py-1 rounded mr-2"
                                        onClick={() => handleEditMessage(message.id)}
                                    >
                                        Edit
                                    </button>
                                    
                                    <button
                                        className="bg-green-700 text-white px-2 py-1 rounded mr-2"
                                        onClick={() => handleViewMessage(message.id)}
                                    >
                                        View
                                    </button>
                                    <button
                                        className="bg-red-700 text-white px-2 py-1 rounded "
                                        onClick={() => handleDeleteMessage(message.id)}
                                       >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
             
                <p className="text-right mt-4">Total Amount: {totalAmount}</p>
            </div>

            {showAddMessageForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                  <form onSubmit={handleSubmit}>
                    <div className="bg-white p-4 rounded shadow-md w-auto">
                        <h2 className="text-xl font-bold mb-4">New Message</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-2">
                            <label>New Message</label>
                            <textarea
                                placeholder="Paste your message here..."
                                className="border rounded w-full px-2 py-1"
                                value={messageText}
                                onChange={handlePasteMessage}
                                
                            />
                        </div>
                        <div className="mb-2">
                            <label>Phone</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newMessage.phone}
                                onChange={(e) => {
                                    const phoneValue = e.target.value;
                                    if (/^\d{0,10}$/.test(phoneValue)) {
                                        setNewMessage({ ...newMessage, phone: phoneValue });

                                        if(phoneValue.length === 10) {
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
                                    } else {
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: 'Phone must be exactly 10 digits' }));
                                    }
                                  }
                                }}
                            />
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Notes</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newMessage.notes}
                                onChange={(e) => setNewMessage({ ...newMessage, notes: e.target.value })}
                            />
                            {errors.notes && <p className="text-red-500">{errors.notes}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Car</label>
                            <select
                                type="text"
                                className="border rounded  w-full px-2 py-1"
                                value={newMessage.car}
                                onChange={(e) => setNewMessage({ ...newMessage, car: e.target.value })}
                            >
                              <option>Select the car</option>
                                {cars.map((car) => (
                                        <option key={car.plate_no} value={car.plate_no}>
                                            {car.plate_no}
                                        </option>
                                    ))}
                            {errors.car && <p className="text-red-500">{errors.car}</p>}
                            </select>
                        </div>
                        <div className="mb-2">
                            <label>Code</label>
                            <input
                              type="text"
                               className="border rounded w-full px-2 py-1"
                               value={newMessage.code}
                               onChange={(e) => {
                                const formattedCode = e.target.value.toUpperCase().slice(0, 10);
                                if (/^[A-Z0-9]{0,10}$/.test(formattedCode)) {
                                    setNewMessage({ ...newMessage, code: formattedCode });
                                    setErrors ((prevErrors) => ({ prevErrors, code: '' }));
                                } else {
                                    setErrors((prevErrors) =>({ prevErrors, code: 'Code must be 10 uppercase letters' }));
                                }
                            }}
                            />
                            {errors.code && <p className="text-red-500">{errors.code}</p>}


                        </div>
                        <div className="mb-2">
                            <label>Time</label>
                            <input
                                type="datetime-local"
                                className="border rounded w-full px-2 py-1"
                                value={newMessage.time}
                                onChange={(e) => setNewMessage({ ...newMessage, time: e.target.value })}
                            />
                            {errors.time && <p className="text-red-500">{errors.time}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Amount</label>
                            <input
                                type="number"
                                className="border rounded w-full px-2 py-1"
                                value={newMessage.amount}
                                onChange={(e) => setNewMessage({ ...newMessage, amount: e.target.value })}
                            />
                            {errors.amount && <p className="text-red-500">{errors.amount}</p>}
                        </div>
                        <div className='flex justify-end col-span-2 space-x-1 mt-4'>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white px-6 py-2 rounded mr-2"
                            onClick={handleAddMessage}
                        >
                            Add
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-6 py-2 rounded "
                            onClick={() => setShowAddMessageForm(false)}
                        >
                            Cancel
                        
                        </button>
                        </div>
                        </div>
                    </div>
                    </form>
                </div>
            )}

            {editingMessage && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-auto">
                        <h2 className="text-xl font-bold mb-4">Edit Message</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-2">
                            <label>Phone</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingMessage.phone}
                                onChange={(e) => {

                                    const phoneValue = e.target.value;

                                    if(/^\d{0,10}$/.test(phoneValue)){
                                        setEditingMessage({  ...editingMessage, phone: phoneValue});
                                        if(phoneValue.length === 10) {
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: ''}));
                                    }else{
                                        setErrors((prevErrors) => ({ ...prevErrors, phone: 'phone must be exactly 10 digits'} ));
                                    }
                                  }
                                    
                                }}
                            />
                            {errors.phone && <p className="text-red-500">{errors.phone}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Notes</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingMessage.notes}
                                onChange={(e) => setEditingMessage({ ...editingMessage, notes: e.target.value })}
                            />
                            {errors.notes && <p className="text-red-500">{errors.notes}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Car</label>
                            <select
                                className="border rounded w-full px-2 py-1"
                                value={editingMessage.car}
                                onChange={(e) => setEditingMessage({ ...editingMessage, car: e.target.value })}
                            >
                                <option>Select the car</option>
                                {cars.map((car) => (
                                        <option key={car.plate_no} value={car.plate_no}>
                                            {car.plate_no}
                                        </option>
                                    ))}

                            </select>
                            {errors.car && <p className="text-red-500">{errors.car}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Code</label>
                            <input
                               type="text"
                               className="border rounded w-full px-2 py-1"
                               value={editingMessage.code}
                               onChange={(e) => {
                               const formattedCode = e.target.value.toUpperCase().slice(0, 10);
                               if (/^[A-Z0-9]{0,10}$/.test(formattedCode)) {
                               setEditingMessage({ ...editingMessage, code: formattedCode });
                               setErrors((prevErrors) => ({ ...prevErrors, code: '' }));
                        } else {
                            setErrors((prevErrors) => ({ ...prevErrors, code: 'code must be 10 uppercase letters' }));
                             }
                         }}
                        />
                              {errors.code && <p className="text-red-500">{errors.code}</p>}           
                        </div>
                        <div className="mb-2">
                            <label>Time</label>
                            <input
                                type="datetime-local"
                                className="border rounded w-full px-2 py-1"
                                value={editingMessage.time}
                                onChange={(e) => setEditingMessage({ ...editingMessage, time: e.target.value })}
                            />
                            {errors.time && <p className="text-red-500">{errors.time}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Amount</label>
                            <input
                                type="number"
                                className="border rounded w-full px-2 py-1"
                                value={editingMessage.amount}
                                onChange={(e) => setEditingMessage({ ...editingMessage, amount: e.target.value })}
                            />
                            {errors.amount && <p className="text-red-500">{errors.amount}</p>}
                        </div>
                        <div className='flex m-6'>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-2 rounded mr-2"
                            onClick={handleUpdateMessage}
                        >
                            Update
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
                            onClick={() => setEditingMessage(null)}
                        >
                            Cancel
                        </button>
                        </div>
                        </div>
                    </div>
                </div>
            )}  

            {viewingMessage && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-96">
                        <h2 className="text-xl font-bold mb-4  text-slate-600 text-center">View Message</h2>
                        <div className="mb-2">
                            <label className='font-bold' >Phone</label>
                            <p >{viewingMessage.phone}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Notes</label>
                            <p>{viewingMessage.notes}</p>
                        </div>
                        <div className="mb-2">
                            <label  className='font-bold'>Code</label>
                            <p>{viewingMessage.code}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Time</label>
                            <p>{new Date(viewingMessage.timestamp).toLocaleString()}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Amount</label>
                            <p>{viewingMessage.amount}</p>
                        </div>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
                            onClick={() => setViewingMessage(null)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyTrackApp;