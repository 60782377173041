import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { useTheme } from './ThemeContext';
import { useAuth } from './AuthContext';


const  MyCarsApp = () => {
    const { rootUrl } = require('../../config/config');
    const [cars, setCars] = useState([]); 
    const { isAuthenticated } = useAuth();
    const [drivers, setDrivers] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');   
    const [searchTerm, setSearchTerm] = useState('');
    const [searchBy, setSearchBy] = useState('plate_no');
    const { theme } = useTheme();
    const [showAddCarForm, setShowAddCarForm] = useState(false);
    const [newCar, setNewCar] = useState({
        plate_no: '',
        model: '',
        make: '',
        insurer: '',
        insurer_expiry: '',
        year:'',
        status:'',
        driver_name:''
        
      
    });
    const [errors, setErrors] = useState({
        plate_no: '',
        model: '',
        make: '',
        insurer: '',
        insurer_expiry: '',
        year: '',
        status:'',
        driver_name:''
    });

   
    
    const [editingCar, setEditingCar] = useState(null);
    const [viewingCar, setViewingCar] = useState(null);

    useEffect(() => {
        const fetchCars = async () => {
              const token = localStorage.getItem('authToken')|| 'default-token';
            try {
                const [carResponse, driverResponse] = await Promise.all([
                    axios.get(`${rootUrl}/cars`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    }),
                    axios.get(`${rootUrl}/drivers`, {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        },
                    })
                ]);
                console.log('Car Data:', carResponse.data);
                console.log('Driver Data:', driverResponse.data); 
                setDrivers(driverResponse.data);

                const carData = carResponse.data

                const mappedData = carData.map(item => ({   
                    plate_no: item.plate_no || 'No sender name', 
                    model: item.model || 'unknown',
                    make: item.make || 'N/A',
                    insurer: item.insurer|| 'N/A',                   
                    insurer_expiry: item.insurer_expiry ? item.insurer_expiry.split('T')[0] : 'N/A', // Ensure it's just the date
                    year: item.year|| 'no Car make',
                    status: item.status || 'no status!', 
                    driver_name:item.driver_name || 'no drivers name',
                    
                }));

                setCars(mappedData);
   

            } catch (error) {
                console.error("Error fetching cars: ", error);
            }
            
        };

        fetchCars();
    }, []);
    

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchByChange = (event) => {
        setSearchBy(event.target.value);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setNewCar((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleDriverNameChange = (event) => {
        const { name, value } = event.target;
        setEditingCar((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    
  
    

    

    const handleAddCar = async () => {
        let newErrors = {};

        if (!newCar.plate_no) newErrors.plate_no = 'Plate Number is Required !';
        if (!newCar.model) newErrors.model = 'Car Text is Required !';
        if (!newCar.make) newErrors.make = 'Car Make is Required !';      
        if (!newCar.year) newErrors.year = 'Year Make is Required !';
        if (!newCar.status) newErrors.status = 'Car Status is Required !'
        if (!newCar.driver_name) newErrors.driver_name = 'Driver Name is Required !';  

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        try {
            let insurerExpiryDate = '';
            if (newCar.insurer_expiry) {
                insurerExpiryDate = newCar.insurer_expiry; 
            }
            

            const carData = {
                
                plate_no: newCar.plate_no,
                model: newCar.model,
                make: newCar.make,  
                insurer: newCar.insurer,
                year: newCar.year,
                status: newCar.status,
                driver_name: newCar.driver_name,

                ...(insurerExpiryDate && { insurer_expiry: insurerExpiryDate })
                
               
            };

            const response = await axios.post(`${rootUrl}/cars`, carData );
            console.log('API response data:', response.data);

            const addedCar = {

                plate_no: response.data.plate_no,
                model: newCar.model,
                make: newCar.make,  
                insurer: newCar.insurer,
                insurer_expiry: insurerExpiryDate || null,
                year: newCar.year,
                status: newCar.status,
                driver_name: newCar.driver_name

                

            }

            console.log('insurance expiry', insurerExpiryDate )
    
            setCars([...cars, addedCar]);
            setShowAddCarForm(false);
            setNewCar({
                plate_no: '',
                model: '',
                make: '',
                insurer: '',
                insurer_expiry:'',
                year: '',
                status:'',
                driver_name:''
                
               
            });

          setSuccessMessage('Car added successfully!');
          setTimeout(() => setSuccessMessage(''), 6000);
            
    
            console.log('Car added:', addedCar);
            
        } catch (error) {
            console.error("Error adding Car: ", error);
        }
    };
    
    
    
    const handleEditCar = (plate_no) => {
        const carToEdit = cars.find(car => car.plate_no === plate_no);
    
        if (carToEdit) {
            const formattedDate = carToEdit.insurer_expiry
                ? new Date(carToEdit.insurer_expiry).toISOString().split('T')[0] 
                : ""; 
    
            setEditingCar({ ...carToEdit, insurer_expiry: formattedDate });
        }
    };
    
    const handleUpdateCar = async () => {
        if (!editingCar) return;
    
        let newErrors = {};
    
      
        if (!editingCar.plate_no || editingCar.plate_no.length !== 8) newErrors.plate_no = 'Plate number must be exactly 8 digits';
        if (!editingCar.model) newErrors.model = 'Model is required';
        if (!editingCar.make) newErrors.make = 'Make is required';
        if (!editingCar.year) newErrors.year = 'Year is required';
        if (!editingCar.status) newErrors.status = 'Status is required';
        if (!editingCar.driver_name) newErrors.driver_name = 'Driver Name is required';
    
        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
       
        const { plate_no, model, make, insurer, status, insurer_expiry, year, driver_name } = editingCar;
    
        let formattedDate = null;
    
        
        if (insurer_expiry) {
            if (isNaN(Date.parse(insurer_expiry))) {
                setErrors((prevErrors) => ({ ...prevErrors, insurer_expiry: 'Invalid date value' }));
                return;
            }
            formattedDate = new Date(insurer_expiry).toISOString().split('T')[0]; 
        }
    
        try {
            
            await axios.put(`${rootUrl}/cars/${plate_no}`, {
                plate_no, model, make, insurer, year, status, driver_name, insurer_expiry: formattedDate
            });
    
            
            const updatedCars = cars.map(car =>
                car.plate_no === plate_no
                    ? { ...car, plate_no, model, make, insurer, insurer_expiry: formattedDate, year, status, driver_name }
                    : car
            );
            setCars(updatedCars);
            setEditingCar(null); 
        } catch (error) {
            console.error("Error updating car: ", error);
        }
    };
     
    
    
    
    const handleDeleteCar = async (plate_no) => {
        const isConfirmed = window.confirm('Are you sure you want to delete this car?');
    
        if (!isConfirmed) {
           
            return;
        }
    
        try {
            await axios.delete(`${rootUrl}/cars/${plate_no}`);
            const updatedCars = cars.filter(car => car.plate_no !== plate_no);
            setCars(updatedCars);
        } catch (error) {
            console.error("Error deleting car: ", error);
        }
    };
    

    const handleViewCar = (plate_no) => {
        const carToView = cars.find(car => car.plate_no === plate_no);
        setViewingCar(carToView);
    };

    const filteredCars = cars.filter(car => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        switch (searchBy) {
            case 'plate_no':
                return car.plate_no.toLowerCase().includes(lowerCaseSearchTerm);
            case 'model':
                return car.model.toLowerCase().includes(lowerCaseSearchTerm);
            case 'make':
                return car.make.toLowerCase().includes(lowerCaseSearchTerm);
            case 'insurer':
                 return car.insurer.toLowerCase().includes(lowerCaseSearchTerm);
             case 'year':
                return car.year.toString().toLowerCase().includes(lowerCaseSearchTerm.toString().toLowerCase());
             case 'status':
                 return car.status.toLowerCase().includes(lowerCaseSearchTerm);    
            case 'insurer_expiry':                
                return new Date(car.timestamp).toLocaleString().toLowerCase().includes(lowerCaseSearchTerm);
            case 'driver_name':
                return car.driver_name.toLowerCase().includes(lowerCaseSearchTerm);
            default: 
               return false;
 
        }
    });

    return (
        <div className={`min-h-screen ${theme}`}>
            <div className="container mx-auto p-4">
                <h2 className="text-center text-2xl font-bold mb-4">Cars Onboard</h2>

                {successMessage && (
                <div className="bg-green-300 text-center text-green-800 p-2 rounded mb-4">
                    {successMessage}
                </div>
            )}

                <div className="flex justify-between items-center mb-4">
                    <div>
                        <label className="mr-2">Search By:</label>
                        <select className="border rounded px-2 py-1" value={searchBy} onChange={handleSearchByChange}>
                            <option value="plate_no">Plate Number</option>
                            <option value="model">Model</option>
                            <option value="make">Make</option>
                            <option value="insurer">Insurer</option>
                            <option value="insurer_expiry">Insurer expiry</option>
                            <option value="year">Year make</option>
                            <option value="status">Status</option>
                            <option value="driver_name">Driver Name</option>
                        </select>
                    </div>
                    <input
                        type="text"
                        placeholder={`Search by ${searchBy}`}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="border rounded px-2 py-1 w-1/3"
                    />
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white px-4 py-1 rounded"
                        onClick={() => setShowAddCarForm(true)}
                    >
                        New Car
                    </button>
                </div>
                   

                <table className="border-collapse w-full">
                    <thead>
                        <tr className="bg-slate-800 text-white">
                            <th className="border px-2 py-2 text-left">Plate number</th>
                            <th className="border px-2 py-2 text-left">Model</th>
                            <th className="border px-2 py-2 text-left">Make</th>
                            <th className="border px-2 py-2 text-left">Insurer</th>
                            <th className="border px-2 py-2 text-left">Insurer Expiry</th>
                            <th className="border px-2 py-2 text-left">Year Make</th>
                            <th className="border px-2 py-2 text-left">Status</th>
                            <th className="border px-2 py-2 text-left">Driver Name</th>
                            <th className="border px-2 py-2 text-left">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredCars.map((car, index) => (
                            <tr key={car.plate_no} className={index % 2 === 0 ? "bg-white" : "bg-slate-200"}>
                                <td className="border px-2 py-2">{car.plate_no}</td>
                                <td className="border px-2 py-2">{car.model}</td>
                                <td className="border px-2 py-2">{car.make}</td>
                                <td className="border px-2 py-2">{car.insurer}</td>                               
                                <td className="border px-2 py-2">{car.insurer_expiry } </td>
                                <td className="border px-2 py-2">{car.year}</td>
                                <td className="border px-2 py-2">{car.status}</td>
                                <td className="border px-2 py-2">{car.driver_name}</td>
                                <td className="border px-2  py-2">
                                    <button
                                        className="bg-slate-400  text-white px-2 py-1 rounded mr-2"
                                        onClick={() => handleEditCar(car.plate_no)}
                                    >
                                        Edit
                                    </button>
                                    
                                    <button
                                        className="bg-green-700 text-white px-2 py-1 rounded mr-2"
                                        onClick={() => handleViewCar(car.plate_no)}
                                    >
                                        View
                                    </button>
                                    <button
                                        className="bg-red-700 text-white px-2 py-1 rounded "
                                        onClick={() => handleDeleteCar(car.plate_no)}
                                       >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {showAddCarForm && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-auto">
                        <h2 className="text-xl font-bold  mb-4">Add Car</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="mb-2">
                           <label>Plate Number</label>
                            <input
                            type="text"
                            className="border rounded w-full px-2 py-1"
                            value={newCar.plate_no}
                            onChange={(e) => {
                            let plate_noValue = e.target.value.toUpperCase(); 
           
                             if (plate_noValue.startsWith('K')) {
              
                            if (/^[A-Z0-9\s]*$/.test(plate_noValue) && plate_noValue.length <= 8) {
                             setNewCar({ ...newCar, plate_no: plate_noValue });

                            if (plate_noValue.length === 8) {
                             setErrors((prevErrors) => ({ ...prevErrors, plate_no: '' }));
                             } 
                            }
                            } else {
                            setErrors((prevErrors) => ({ ...prevErrors, plate_no: 'Plate no start with "K" & must be 8 character' }));
                             }
                            }}
                         />
                           {errors.plate_no && <p className="text-red-500">{errors.plate_no}</p>}
                        </div>


                        <div className="mb-2">
                            <label>Model</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.model}
                                onChange={(e) => setNewCar({ ...newCar, model: e.target.value })}
                            />
                            {errors.model && <p className="text-red-500">{errors.model}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Make</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.make}
                                onChange={(e) => setNewCar({ ...newCar, make: e.target.value })}
                            />
                            {errors.make && <p className="text-red-500">{errors.make}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Insurer</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.insurer}
                                onChange={(e) => setNewCar({ ...newCar, insurer: e.target.value })}
                            />
                            {errors.insurer && <p className="text-red-500">{errors.insurer}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Insurance Expiry</label>
                            <input
                                type="date"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.insurer_expiry}
                                onChange={(e) => setNewCar({ ...newCar, insurer_expiry: e.target.value })}
                            />
                            {errors.insurer_expiry && <p className="text-red-500">{errors.insurer_expiry}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Year Make</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.year}
                                onChange={(e) => setNewCar({ ...newCar, year: e.target.value })}
                            />
                            {errors.year && <p className="text-red-500">{errors.year}</p>}
                        </div>
                        <div className="mb-2">
                            <label>Status</label>
                            <select
                                
                                className="border rounded w-full px-2 py-1"
                                value={newCar.status}
                                onChange={(e) => setNewCar({...newCar, status: e.target.value})}
                            >
                                <option>AVAILABLE</option>
                                <option>ASSIGNED</option>
                                <option>UNDER MAINTENANCE</option>
                                <option>RESERVED</option>
                                <option>OUT OF SERVICE</option>
                                <option>RETIRED</option>
                                <option>WAITING ASSIGNMENT</option>
                                <option>SCHEDULED FOR DISPOSAL</option>
                                <option>PENDING INSPECTION</option>
                                <option>DECOMMISSIONED</option>
                            </select>

                            {errors.status && <p className="text-red-500">{errors.status}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Driver Name</label>
                            <select
                                name="driver_name"
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={newCar.driver_name}
                                onChange= {handleInputChange}
                            >
                                <option value=''>Select the driver</option>
                                <option value='N/A'>N/A</option>
                                {drivers.map((driver) => (
                                        <option key={driver.id} value={driver.name}>
                                            {driver.name}
                                        </option>
                                    ))}
                            </select>
                            {errors.driver_name && <p className="text-red-500">{errors.driver_name}</p>}
                        </div>
                        <div className='col-span-2 flex justify-end items-center space-x-1 mt-4'>

                        
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white px-6 py-2 rounded mr-2"
                            onClick={handleAddCar}
                        >
                            Add
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-6 py-2 rounded "
                            onClick={() => setShowAddCarForm(false)}
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
            )}

            {editingCar && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-auto">
                     <h2 className="text-xl font-bold mb-4">Edit Car</h2>
                    <div className=" grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="mb-2">
                     <label htmlFor="plateNumber">Plate Number</label>
                     <input
                      id="plateNumber"
                      type="text"
                      className="border rounded w-full px-2 py-1"
                      value={editingCar.plate_no}
                      onChange={(e) => {
                 const plate_noValue = e.target.value.toUpperCase();

                 if (plate_noValue.startsWith('K')) {
                 if (/^[A-Z0-9\s]*$/.test(plate_noValue) && plate_noValue.length <= 8) {
                    setEditingCar({ ...editingCar, plate_no: plate_noValue });

                    if (plate_noValue.length === 8) {
                        setErrors((prevErrors) => ({ ...prevErrors, plate_no: '' }));
                          }
                            }
                                } else {
                                 setErrors((prevErrors) => ({ ...prevErrors, plate_no: 'Plate number must start with "K"' }));
                              }
                              }}
                             />
                           {errors.plate_no && <p className="text-red-500">{errors.plate_no}</p>}
                        </div>

                        <div className="mb-1">
                            <label>Model</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.model}
                                onChange={(e) => setEditingCar({ ...editingCar, model: e.target.value })}
                            />
                            {errors.model && <p className="text-red-500">{errors.model}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Make</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.make}
                                onChange={(e) => setEditingCar({ ...editingCar, make: e.target.value })}
                            />
                            {errors.make && <p className="text-red-500">{errors.make}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Insurer</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.insurer}
                                onChange={(e) => setEditingCar({ ...editingCar, insurer: e.target.value })}
                            />
                            {errors.insurer && <p className="text-red-500">{errors.insurer}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Insurance Expiry</label>
                            <input
                                type="date"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.insurer_expiry}
                                onChange={(e) => setEditingCar({ ...editingCar, insurer_expiry: e.target.value })}
                            />
                            {errors.insurer_expiry && <p className="text-red-500">{errors.insurer_expiry}</p>}
                        </div>
                        <div className="mb-1">
                            <label>Year Make</label>
                            <input
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.year}
                                onChange={(e) => setEditingCar({ ...editingCar, year: e.target.value })}
                            />
                            {errors.year && <p className="text-red-500">{errors.year}</p>}
                        </div>
                        <div>
                            <label>Status</label>
                            <select
                               className="border rounded w-full px-2 py-1"
                               value={editingCar.status}
                               onChange= {(e) => setEditingCar({ ...editingCar, status: e.target.value})}
                            >
                                <option>AVAILABLE</option>
                                <option>ASSIGNED</option>
                                <option>UNDER MAINTENANCE</option>
                                <option>RESERVED</option>
                                <option>OUT OF SERVICE</option>
                                <option>RETIRED</option>
                                <option>WAITING ASSIGNMENT</option>
                                <option>SCHEDULED FOR DISPOSAL</option>
                                <option>PENDING INSPECTION</option>
                                <option>DECOMMISSIONED</option> 
                            </select>
                        </div>
                        <div className="mb-1">
                            <label>Driver Name</label>
                            <select
                                name="driver_name"
                                type="text"
                                className="border rounded w-full px-2 py-1"
                                value={editingCar.driver_name}
                                onChange={handleDriverNameChange}
                            >
                                <option>select the driver</option>
                                {drivers.map((driver) => (
                                        <option key={driver.id} value={driver.name}>
                                            {driver.name}
                                        </option>
                                    ))}


                            </select>
                            {errors.driver_name && <p className="text-red-500">{errors.driver_name}</p>}
                        </div>
                        <div className='col-span-2 flex justify-end space-x-1 mt-4 items-center'>
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white px-6 py-2 rounded mr-2"
                            onClick={handleUpdateCar}
                        >
                            Update
                        </button>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-6 py-2 rounded"
                            onClick={() => setEditingCar(null)}
                        >
                            Cancel
                        </button>
                        </div>
                    </div>
                </div>
                </div>
            )}
            {viewingCar && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded shadow-md w-96">
                        <h2 className="text-xl font-bold mb-4  text-slate-600 text-center">View Car</h2>
                        <div className="mb-2">
                            <label className='font-bold' >Plate Number</label>
                            <p >{viewingCar.plate_no}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Model</label>
                            <p>{viewingCar.model}</p>
                        </div>
                        <div className="mb-2">
                            <label className='font-bold'>Make</label>
                            <p>{viewingCar.make}</p>
                        </div>
                        <div className="mb-2">
                            <label  className='font-bold'>Insurer</label>
                            <p>{viewingCar.insurer}</p>
                        </div>
                       
                        <div className="mb-2">
                            <label className='font-bold'>Insurance Expiry</label>
                            <p>{new Date(viewingCar.timestamp).toLocaleString()}</p>
                        </div>
                        <div className="mb-2">
                            <label  className='font-bold'>Year Make</label>
                            <p>{viewingCar.year}</p>
                        </div>
                        <div>
                            <label className="font-bold">Status</label>
                            <p>{viewingCar.status}</p>
                        </div>
                        <div className="mb-2">
                            <label  className='font-bold'>Driver Name</label>
                            <p>{viewingCar.driver_name}</p>
                        </div>
                        <button
                            className="bg-gray-500 hover:bg-gray-700 text-white px-4 py-2 rounded"
                            onClick={() => setViewingCar(null)}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
export default MyCarsApp;
